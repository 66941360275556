import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const ThanksPage = () => (
  <Layout>
    <section className="section" style={{ marginTop: 140 }}>
      <div className="container">
        <ThanksSection className="content">
          <h1 style={{ color: "white" }}>Thank you!</h1>
          <p style={{ color: "white" }}>We will communicate with you </p>
        </ThanksSection>
      </div>
    </section>
  </Layout>
)

export default ThanksPage

const ThanksSection = styled.div`
  h1 {
    color: white;
    font-size: 4rem;
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    margin-bottom: 0.8em;
    padding-bottom: 0.4em;
    letter-spacing: 0.2rem;
  }
  p {
    color: white;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
  }
`
